import { createAction, props } from '@ngrx/store';
import { PrintBol, PrintBolLanding } from '../../interfaces/print-bol.interface';

export enum PrintBolActionsTypes {

    getPrintBol = '[PrintBol] Get PrintBol',
    successGetPrintBol='[PrintBol] Success Get PrintBol',
    errorGetPrintBol='[PrintBol] Error Get PrintBol' ,

    getPrintBolLanding = '[PrintBol] Get PrintBol Landing',
    successGetPrintBolLanding='[PrintBol] Success Get PrintBol Landing',
    errorGetPrintBolLanding='[PrintBol] Error Get PrintBol Landing' ,
}

export const getPrintBol = createAction(PrintBolActionsTypes.getPrintBol,props<{filters:string}>());
export const successGetPrintBol= createAction(PrintBolActionsTypes.successGetPrintBol,props<{ printbol: Array<PrintBol> }>());
export const errorGetPrintBol = createAction(PrintBolActionsTypes.errorGetPrintBol, props<{ payload: any }>());


export const getPrintBolLanding = createAction(PrintBolActionsTypes.getPrintBolLanding,props<{filters:string}>());
export const successGetPrintBolLanding= createAction(PrintBolActionsTypes.successGetPrintBolLanding,props<{ printbol: Array<PrintBolLanding> }>());
export const errorGetPrintBoLanding = createAction(PrintBolActionsTypes.errorGetPrintBolLanding, props<{ payload: any }>());